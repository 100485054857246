import React, { useEffect, useState } from "react";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import style from "./myStudioRoomView.module.css";
import ThreeDot from "../public/lottie/ThreeDot.svg";
import { supabase } from "../client";
// import Search from '../public/lottie/chevron_right.svg'
import { Button } from "@material-ui/core";
import HotspotImage from "../components/Hotspot";
import { useParams, Link } from "react-router-dom";
import AddProduct from "../services/addproduct";
import AddTexture from "../services/addTexture";
import Dropdown from "react-bootstrap/Dropdown";

const MyStudioRoomDetails = ({ history }) => {
  const [moodboard, setMoodboard] = useState([]);
  const [mprice, setMprice] = useState("");
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [eliyaID, setELiyaID] = useState("");
  const userID = localStorage.getItem("user");
  const [Materials, setMaterials] = useState([]);
  // console.log(id)
  const getData = async () => {
    let moodBoardData = [];
    const moodboardData = await supabase
      .from("moodboards")
      .select("*")
      .eq("id", id);
    const price = moodboardData.data[0].price;
    const modeller = moodboardData.data[0].modellerid;
    if (modeller === userID) {
      if (moodboardData.data[0].materials) {
        const textures = await supabase
          .from("textureAssets")
          .select("id,textureName,tags, textureImage, reffcode")
          .in("reffcode", moodboardData.data[0].materials);
        // console.log(textures)
        setMaterials(textures.data);
      }
      setELiyaID(moodboardData.data[0].eliyaid);
      const lakhs = price / 100000;
      const refined = lakhs.toFixed(1);
      setMprice(`${refined} lakhs`);
      if (moodboardData.data[0].products.length !== 0 ) {
        const products = await supabase
          .from("asset_data")
          .select(
            "id,product_db_id,pixelID,productBrand,productImage,productColor,modelStatus,productName,glbLink"
          )
          .in("product_db_id", moodboardData.data[0].products);
        moodBoardData.push({ moodboard: moodboardData, products: products });
      }else{
        moodBoardData.push({ moodboard: moodboardData, products: [] }); // console.log(products.data)
      }
      setMoodboard(moodBoardData);
      setDetails(moodboardData.data[0]);
    } else {
      alert("Not Authorized");
      history.push("/room-view");
    }
  };

  const currentTime = new Date().getTime() / 1000;

  const removeProduct = async (prodid) => {
    const exisprods = details.products;
    exisprods.splice(exisprods.indexOf(prodid), 1);
    await supabase
      .from("moodboards")
      .update({ products: exisprods })
      .eq("id", id);
    getData();
  };
  const removeMaterial = async (matcode) => {
    const exismaterial = details.materials;
    exismaterial.splice(exismaterial.indexOf(matcode), 1);
    await supabase
      .from("moodboards")
      .update({ materials: exismaterial })
      .eq("id", id);
    getData();
    // console.log(exismaterial)
  };
  useEffect(() => {
    if (userID) {
      const expiry = localStorage.getItem("expires_at");
      if (currentTime < expiry) {
        getData();
      } else {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  }, []);

  const [hotSpotModal, setHotSpotModal] = useState(false);
  return (
    <>
      <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
        <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
          <Sidebar />
          <div className="w-screen h-screen overflow-auto">
            <Topbar />
            <div className="flex flex-col pl-5 pt-4 pr-4 2xl:gap-14 xl:gap-8 lg:gap-6 md:gap-4">
              <div className="flex flex-row 2xl:gap-20 xl:gap-8 lg:gap-6 md:gap-2 text-white">
                <div className="flex w-3/5  bg-white rounded-lg">
                  <img className="w-full object-contain" src={details.previewurl} alt="" />
                </div>
                <div className="flex flex-col gap-10 w-2/5">
                  <div
                    className="flex flex-row gap-2 justify-around w-full"
                    style={{
                      // position: "relative",
                      // display: "flex",
                      width: "100%",
                    }}
                  >
                  {/* {details.roomstatus !== "Done" && ( */}
                      <button
                        onClick={() => setHotSpotModal(true)}
                        className="flex px-4 py-2 items-center justify-center rounded-md text-2xl text-black bg-tealblue"
                      >
                        Upload Render
                      </button>
                    {/* )} */}
                    <div className="flex flex-row gap-3 bg-tealblue text-black rounded-md text-2xl px-8 py-2 items-center justify-center">
                      {details.roomstatus}
                      <div>
                        <img className="h-[15px]" src={ThreeDot} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 pb-2 pr-5 pt-2 2xl:text-lg xl:text-md lg:text-sm rounded-md justify-center pl-5 bg-neutral-700">
                    <div className={`${style.roomname} flex`}>
                      <div className="flex xl:text-base 2xl:text-lg items-center">
                        Moodboard Name :
                        <span className="2xl:text-2xl max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis xl:text-2xl lg:text-xl md:text-lg">
                          &nbsp;{details.name}
                        </span>
                      </div>
                    </div>
                    <div className={`${style.roomstyle} flex`}>
                      <div className="flex xl:text-base 2xl:text-lg items-center">
                        Style :
                        <span className="2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg">
                          &nbsp;{details.styletype}
                        </span>
                      </div>
                    </div>
                    <div className={style.roomtype}>
                      <div className="flex xl:text-base 2xl:text-lg items-center">
                        Room Type :
                        <span className="2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg">
                          &nbsp;{details.roomtype}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={style.texturelist}>
                    <h3>Textures :</h3>
                    {/* <div className="grid grid-flow-row 2xl:grid-cols-3 2xl:gap-8 xl:grid-cols-3 xl:gap-4 lg:grid-cols-2 lg:gap-4 md:grid-cols-2 md:gap-2">
                      {Materials &&
                        Materials.map((mat) => (
                          <div className="flex flex-col gap-2">
                            <img
                              className="flex w-[140px] h-[140px] border"
                              src={mat.textureImage[0]}
                              alt=""
                            />
                            <div className=" text-ellipsis w-[100px] h-[50px] overflow-hidden">
                              {mat.textureName}
                            </div>
                          </div>
                        ))}
                     //   {details.roomstatus !== "Done" && (
                        <AddTexture 
                        roomid={id}
                        materials={details.materials}
                        getData={getData}
                        />
                      )}//
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-[100%]">
                <div className="2xl:w-[50%] xl:w-[45%]">
                  <div className="grid w-[95%] grid-flow-row 2xl:grid-cols-3 2xl:gap-8 xl:grid-cols-2 xl:gap-8 lg:grid-cols-2 lg:gap-4 md:grid-cols-2 md:gap-2">
                    {Materials &&
                      Materials.map((mat) => (
                        <div className="flex justify-center flex-col gap-2">
                          <div className="flex gap-2 flex-row jus ">
                            <img
                              className="flex w-[180px] h-[180px] border"
                              src={mat.textureImage[0]}
                              alt=""
                            />
                            <Dropdown>
                              <Dropdown.Toggle
                                style={{
                                  backgroundColor: "#343434",
                                  width: "10px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "20px",
                                  border: "none",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              ></Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => removeMaterial(mat.reffcode)}
                                >
                                  Remove
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="  text-white w-[140px] whitespace-nowrap overflow-hidden text-ellipsis text-center">
                            {mat.textureName}
                          </div>
                          <div className="text-white">
                            Material = {mat.tags}
                          </div>
                        </div>
                      ))}
                  //  {details.roomstatus !== "Done" && (
                      <AddTexture
                        roomid={id}
                        materials={details.materials}
                        getData={getData}
                      />
                    )}//
                  </div>
                </div>
                {/* <div className="container p-6">
                  <div className="w-full mb-8 from-neutral-900 via-neutral-700 to-neutral-900 bg-gradient-to-t overflow-hidden rounded-lg shadow-lg">
                    <div className="w-full px-4 pt-4 pb-8 overflow-x-auto border">
                      <table className="w-full">
                        <thead>
                          <tr className="text-md font-thin tracking-wide text-left text-white uppercase border-gray-600">
                            <th className="px-4 py-3 font-medium">Name</th>
                            <th className="px-4 py-3 font-medium">Date</th>
                            <th className="px-4 py-3 font-medium">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-white hover:bg-neutral-700 border-b border-white">
                            <td className="px-1 py-1">
                              <div className="flex flex-row items-center text-sm">
                                <div className="relative w-6 h-8 rounded-full md:block">
                                  <div
                                    className="absolute inset-0 rounded-full shadow-inner"
                                    aria-hidden="true"
                                  ></div>
                                </div>
                                <div className="text-xl">pxm_esoa_v4.blend</div>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-xl">6/4/2000</td>
                            <td className="flex flex-row  gap-2 px-3 py-3 text-xl">
                              <div className="px-2 py-1 font-semibold">
                                Submitted
                              </div>
                              <img src={ThreeDot} alt="" />
                            </td>
                          </tr>
                          <tr className="text-white border-b border-white">
                            <td className="px-1 py-1">
                              <div className="flex flex-row items-center text-sm">
                                <div className="relative w-6 h-8 rounded-full md:block">
                                  <div
                                    className="absolute inset-0 rounded-full shadow-inner"
                                    aria-hidden="true"
                                  ></div>
                                </div>
                                <div className="text-xl">pxm_esoa_v4.blend</div>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-xl">6/4/2000</td>
                            <td className="flex flex-row  gap-2 px-3 py-3 text-xl">
                              <div className="px-2 py-1 font-semibold">
                                Submitted
                              </div>
                              <img src={ThreeDot} alt="" />
                            </td>
                          </tr>
                        </tbody>
                      </table> 
                      <div className="flex text-white pt-2 text-xl hover:underline cursor-pointer justify-center">
                        Drag & Drop to Upload
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="flex flex-col text-white w-[60%] gap-20">
                  <div className="flex flex-col gap-8 pr-4">
                    {/* <div className="w-max grid grid-flow-row 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 2xl:gap-x-56 xl:gap-x-16 lg:gap-x-20 gap-y-10 pr-4"> */}
                    {JSON.stringify(moodboard) !== "[]" &&  moodboard[0].products.data &&
                      moodboard[0].products.data.map((product) => (
                        <div className="w-full flex flex-row rounded-md bg-neutral-700 items-center 2xl:pl-5 xl:pl-3 lg:pl-3 md:pl-3 pr-6">
                          <div className="flex flex-row 2xl:gap-10 xl:gap-4 lg:gap-4 md:gap-4 items-center">
                            <Link to={`/product/${product.id}`} target="_blank">
                              <div className="flex w-[100px] h-[100px] relative top-[-20px] rounded-sm bg-white">
                                <img src={product.productImage[0]} alt="" />
                              </div>
                            </Link>
                            <div className="w-max flex flex-col gap-4 justify-center items-center">
                              <div> {product.productName}</div>
                              <div>{product.productBrand}</div>
                            </div>
                          </div>
                          <div className="w-full flex flex-row 2xl:gap-8 xl:gap-6 items-center justify-end">
                            <div className="flex text-lg border-4 px-12 py-1 rounded-full text-black font-bold border-orange-400 items-center justify-center bg-oran">
                              {product.modelStatus}
                            </div>
                            <div>
                              {/* <img src={ThreeDot} alt="" /> */}
                              <Dropdown>
                                <Dropdown.Toggle
                                  style={{
                                    backgroundColor: "#343434",
                                    width: "10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "20px",
                                    border: "none",
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                ></Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      removeProduct(product.product_db_id)
                                    }
                                  >
                                    Remove
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      ))}
                    {details.roomstatus !== "Done" && (
                      <AddProduct
                        roomid={id}
                        products={details.products}
                        getData={getData}
                      />
                    )}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HotspotImage
        showModal={hotSpotModal}
        setShowModal={setHotSpotModal}
        productlist={moodboard}
        eliyaID={eliyaID}
        materials={Materials}
      />
    </>
  );
};

export default MyStudioRoomDetails;
